<template>
  <div class="space-y-2">
    <!-- Existing pot size quantities -->
    <div v-for="(item, index) in modelValue" :key="index" class="flex items-center gap-1">
      <div class="flex-1">
        <Select 
          :model-value="item.size"
          @update:model-value="(val) => updateSize(index, val as PotSizeKey)"
        >
          <SelectTrigger class="h-8 text-xs">
            <SelectValue :placeholder="item.size" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem 
              v-for="size in availablePotSizes(index)" 
              :key="size" 
              :value="size"
            >
              {{ size }}
            </SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div class="flex-1">
        <NumberField 
          :model-value="item.quantity"
          @update:model-value="(val) => handleQuantityChange(index, val)"
          class="h-8"
        >
          <NumberFieldContent class="h-8">
            <NumberFieldDecrement />
            <NumberFieldInput 
              @input="(e) => handleImmediateInput(e, index)"
              :min="0"
              :max="1000"
              class="h-8 text-xs"
            />
            <NumberFieldIncrement />
          </NumberFieldContent>
        </NumberField>
      </div>

      <div class="w-6 h-8">
        <Button 
          v-if="index !== modelValue.length - 1"
          variant="ghost" 
          size="icon"
          @click="removeItem(index)"
          class="h-8 w-6"
        >
          <XIcon class="h-3 w-3" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { XIcon } from 'lucide-vue-next'
import type { PotSizeQuantity, PotSizeKey } from '~/types/maintenance'
import { Button } from '@/components/ui/button'
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue 
} from '@/components/ui/select'
import {
  NumberField,
  NumberFieldContent,
  NumberFieldDecrement,
  NumberFieldIncrement,
  NumberFieldInput,
} from '@/components/ui/number-field'

const props = defineProps<{
  modelValue: PotSizeQuantity[]
}>()

const emit = defineEmits<{
  'update:modelValue': [value: PotSizeQuantity[]]
}>()

// All available pot sizes
const allPotSizes = ['25-50L', '75-100L', '250L'] as const

// Get available pot sizes (excluding ones already selected)
const availablePotSizes = (currentIndex: number) => {
  const selectedSizes = props.modelValue
    .map((item, i) => i !== currentIndex ? item.size : null)
    .filter(Boolean)
  return allPotSizes.filter(size => !selectedSizes.includes(size))
}

// Update size for a specific item
const updateSize = (index: number, newSize: PotSizeKey) => {
  const newValue = [...props.modelValue]
  newValue[index] = { ...newValue[index], size: newSize }
  emit('update:modelValue', newValue)
}

// Update quantity handler for increment/decrement buttons
const handleQuantityChange = (index: number, newQuantity: number) => {
  if (!isNaN(newQuantity) && newQuantity >= 0 && newQuantity <= 1000) {
    updateQuantity(index, newQuantity);
  }
}

// Immediate input handler for keyboard input
const handleImmediateInput = (event: Event, index: number) => {
  const input = event.target as HTMLInputElement;
  const value = input.value ? parseInt(input.value) : 0;
  
  if (!isNaN(value) && value >= 0 && value <= 1000) {
    updateQuantity(index, value);
  }
}

// Remove an item
const removeItem = (index: number) => {
  const newValue = props.modelValue.filter((_, i) => i !== index)
  emit('update:modelValue', newValue)
}

// Add a new pot size
const addNewPotSize = () => {
  const usedSizes = props.modelValue.map(item => item.size)
  const availableSize = allPotSizes.find(size => !usedSizes.includes(size))
  if (availableSize) {
    emit('update:modelValue', [
      ...props.modelValue,
      { size: availableSize, quantity: 0 }
    ])
  }
}

// Watch for changes in quantities and add new pot size if needed
watch(() => props.modelValue, (newValue) => {
  // Check if we need to add a new pot size
  const shouldAddNew = newValue.some(item => item.quantity > 0) && 
                      newValue.length < allPotSizes.length &&
                      !newValue.some(item => item.quantity === 0)
  
  if (shouldAddNew) {
    addNewPotSize()
  }
}, { deep: true })

// Update quantity and emit change
const updateQuantity = (index: number, newQuantity: number) => {
  const newValue = [...props.modelValue];
  newValue[index] = { ...newValue[index], quantity: newQuantity };
  emit('update:modelValue', newValue);
}
</script> 