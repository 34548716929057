export const tree_costs = {
  "volume_25_50L": {
    "concrete_cutting": { p0: 567.35, p5: 615.92, p25: 678.28, p50: 725.00, p75: 771.71, p95: 834.06, p100: 883.63 },
    "supply": { p0: 57.74, p5: 62.66, p25: 69.00, p50: 73.75, p75: 78.50, p95: 84.84, p100: 89.84 },
    "tree_installation": { p0: 120.92, p5: 131.28, p25: 144.70, p50: 154.75, p75: 164.80, p95: 178.21, p100: 188.93 },
    "mulch_cost": { p0: 46.96, p5: 50.97, p25: 56.13, p50: 60.00, p75: 63.87, p95: 69.02, p100: 73.08 },
    "stakes_and_ties": { p0: 46.97, p5: 50.97, p25: 56.00, p50: 60.00, p75: 63.87, p95: 69.02, p100: 73.10 },
    "installation_cost": { p0: 54.78, p5: 59.47, p25: 65.49, p50: 70.00, p75: 74.51, p95: 80.53, p100: 85.38 },
    "machine_rate": { p0: 105.70, p5: 114.69, p25: 126.30, p50: 135.00, p75: 143.70, p95: 155.31, p100: 164.66 },
    "tree_protection_fencing": { p0: 100.00, p5: 151.22, p25: 214.56, p50: 250.00, p75: 317.71, p95: 390.83, p100: 450.00 },
    "tree_removal": { p0: 225.00, p5: 321.90, p25: 709.50, p50: 871.00, p75: 2737.50, p95: 3667.50, p100: 3900.00 },
    "soil_cost": { p0: 55.28, p5: 61.80, p25: 70.20, p50: 76.50, p75: 82.80, p95: 91.20, p100: 97.81 },
    "maintenance": { p0: 88.91, p5: 96.56, p25: 106.34, p50: 113.67, p75: 120.99, p95: 130.77, p100: 138.55 },
    "traffic_control": { p0: 0.00, p5: 0.00, p25: 0.00, p50: 0.00, p75: 0.00, p95: 0.00, p100: 0.00 },
    "guard_rails": { p0: 192.00, p5: 200.00, p25: 210.00, p50: 224.00, p75: 240.00, p95: 250.00, p100: 261.00 },
    "arborist_inspection": { p0: 100.00, p5: 151.22, p25: 214.56, p50: 250.00, p75: 317.71, p95: 390.83, p100: 450.00 },
    "gis_mapping": { p0: 1.50, p5: 1.78, p25: 2.14, p50: 2.40, p75: 2.66, p95: 3.02, p100: 3.30 },
    "watering": { p0: 2.00, p5: 2.20, p25: 2.20, p50: 4.00, p75: 2.20, p95: 2.20, p100: 6.00 },
    "visual_inspection": { p0: 1.50, p5: 1.65, p25: 2.25, p50: 3.00, p75: 4.00, p95: 4.80, p100: 5.00 }
  },
  "volume_75_100L": {
    "concrete_cutting": { p0: 663.81, p5: 720.63, p25: 793.58, p50: 848.25, p75: 902.90, p95: 975.85, p100: 1033.84 },
    "supply": { p0: 67.56, p5: 73.31, p25: 80.73, p50: 86.29, p75: 91.85, p95: 99.27, p100: 105.12 },
    "tree_installation": { p0: 141.47, p5: 153.60, p25: 169.30, p50: 181.06, p75: 192.81, p95: 208.50, p100: 221.05 },
    "mulch_cost": { p0: 54.95, p5: 59.64, p25: 65.68, p50: 70.20, p75: 74.72, p95: 80.76, p100: 85.51 },
    "stakes_and_ties": { p0: 54.95, p5: 59.64, p25: 65.68, p50: 70.20, p75: 74.72, p95: 80.76, p100: 85.53 },
    "installation_cost": { p0: 64.09, p5: 69.58, p25: 76.62, p50: 81.90, p75: 87.18, p95: 94.22, p100: 99.89 },
    "machine_rate": { p0: 123.67, p5: 134.19, p25: 147.77, p50: 157.95, p75: 168.13, p95: 181.71, p100: 192.66 },
    "tree_protection_fencing": { p0: 100.00, p5: 151.22, p25: 214.56, p50: 250.00, p75: 317.71, p95: 390.83, p100: 450.00 },
    "tree_removal": { p0: 225.00, p5: 321.90, p25: 709.50, p50: 871.00, p75: 2737.50, p95: 3667.50, p100: 3900.00 },
    "soil_cost": { p0: 64.68, p5: 72.30, p25: 82.14, p50: 89.51, p75: 96.87, p95: 106.70, p100: 114.44 },
    "maintenance": { p0: 104.03, p5: 112.98, p25: 124.42, p50: 132.99, p75: 141.56, p95: 153.00, p100: 162.10 },
    "traffic_control": { p0: 0, p5: 0, p25: 0, p50: 0, p75: 0, p95: 0, p100: 0 },
    "guard_rails": { p0: 192.00, p5: 200.00, p25: 210.00, p50: 224.00, p75: 240.00, p95: 250.00, p100: 261.00 },
    "arborist_inspection": { p0: 100.00, p5: 151.22, p25: 214.56, p50: 250.00, p75: 317.71, p95: 390.83, p100: 450.00 },
    "gis_mapping": { p0: 1.50, p5: 1.78, p25: 2.14, p50: 2.40, p75: 2.66, p95: 3.02, p100: 3.30 },
    "watering": { p0: 2.00, p5: 2.20, p25: 2.20, p50: 4.00, p75: 2.20, p95: 2.20, p100: 6.00 },
    "visual_inspection": { p0: 1.50, p5: 1.65, p25: 2.25, p50: 3.00, p75: 4.00, p95: 4.80, p100: 5.00 }
  },
  "volume_250L": {
    "concrete_cutting": { p0: 1180.10, p5: 1281.12, p25: 1410.82, p50: 1508.00, p75: 1605.16, p95: 1734.84, p100: 1837.94 },
    "supply": { p0: 120.10, p5: 130.32, p25: 143.51, p50: 153.40, p75: 163.28, p95: 176.47, p100: 186.87 },
    "tree_installation": { p0: 251.50, p5: 273.07, p25: 300.97, p50: 321.88, p75: 342.78, p95: 370.67, p100: 392.98 },
    "mulch_cost": { p0: 97.68, p5: 106.03, p25: 116.76, p50: 124.80, p75: 132.84, p95: 143.57, p100: 152.01 },
    "stakes_and_ties": { p0: 97.69, p5: 106.02, p25: 116.76, p50: 124.80, p75: 132.84, p95: 143.57, p100: 152.06 },
    "installation_cost": { p0: 113.94, p5: 123.70, p25: 136.22, p50: 145.60, p75: 154.98, p95: 167.50, p100: 177.58 },
    "machine_rate": { p0: 219.86, p5: 238.55, p25: 262.71, p50: 280.80, p75: 298.89, p95: 323.04, p100: 342.50 },
    "tree_protection_fencing": { p0: 100.00, p5: 151.22, p25: 214.56, p50: 250.00, p75: 317.71, p95: 390.83, p100: 450.00 },
    "tree_removal": { p0: 225.00, p5: 321.90, p25: 709.50, p50: 871.00, p75: 2737.50, p95: 3667.50, p100: 3900.00 },
    "soil_cost": { p0: 114.99, p5: 128.54, p25: 146.02, p50: 159.12, p75: 172.22, p95: 189.70, p100: 203.45 },
    "maintenance": { p0: 184.94, p5: 200.85, p25: 221.19, p50: 236.43, p75: 251.66, p95: 271.99, p100: 288.17 },
    "traffic_control": { p0: 0.00, p5: 0.00, p25: 0.00, p50: 0.00, p75: 0.00, p95: 0.00, p100: 0.00 },
    "guard_rails": { p0: 192.00, p5: 200.00, p25: 210.00, p50: 224.00, p75: 240.00, p95: 250.00, p100: 261.00 },
    "arborist_inspection": { p0: 100.00, p5: 151.22, p25: 214.56, p50: 250.00, p75: 317.71, p95: 390.83, p100: 450.00 },
    "gis_mapping": { p0: 1.50, p5: 1.78, p25: 2.14, p50: 2.40, p75: 2.66, p95: 3.02, p100: 3.30 },
    "watering": { p0: 2.00, p5: 2.20, p25: 2.20, p50: 4.00, p75: 2.20, p95: 2.20, p100: 6.00 },
    "visual_inspection": { p0: 1.50, p5: 1.65, p25: 2.25, p50: 3.00, p75: 4.00, p95: 4.80, p100: 5.00 }
  }
}

export const pot_size_mapping = {
  "25-50L": "volume_25_50L",
  "75-100L": "volume_75_100L",
  "250L": "volume_250L"
} as const

export const costCategories = {
  establishment: {
    "Concrete cutting": {
      unitCost: "concrete_cutting",
      quantity: "number_of_trees_requiring_concrete_cutting"
    },
    "Supply": {
      unitCost: "supply",
      useDefaultTrees: true
    },
    "Soil cost": {
      unitCost: "soil_cost",
      quantity: "volume_of_required_soil",
      useDefaultTrees: true
    },
    "Mulch cost": {
      unitCost: "mulch_cost",
      quantity: "volume_of_mulch_required",
      useDefaultTrees: true
    },
    "Stakes and ties": {
      unitCost: "stakes_and_ties",
      useDefaultTrees: true,
      param: "stakes_and_ties"
    },
    "Tree installation": {
      unitCost: "tree_installation",
      useDefaultTrees: true
    },
    "Tree protection fencing": {
      unitCost: "tree_protection_fencing",
      useDefaultTrees: true
    }
  },
  maintenance: {
    annual: {
      "Tree inspections": {
        types: {
          "Arborist": {
            unitCost: "arborist_tree_health_inspection",
            quantity: "estimated_annual_number_of_trees_for_arborist_inspection",
            frequency: "arborist_tree_health_inspection_frequency",
            param: "arborist_inspection",
            defaultQuantity: 2,
            defaultCost: 250.00
          },
          "Visual": {
            unitCost: "visual_tree_inspection",
            quantity: "number_of_trees_inspected",
            frequency: "visual_tree_inspection_frequency",
            param: "visual_inspection",
            defaultQuantity: 100,
            defaultCost: 3.00
          }
        }
      }
    },
    oneTime: {
      "GIS mapping and inventory assessment": {
        unitCost: "gis_mapping_and_inventory_assessment",
        param: "gis_mapping",
        defaultCost: 2.40
      }
    }
  },
  life_cycle_costs: {
    "Establishment": {
      calculate: (inputs: any, yearlyData: any) => {
        const appraisalPeriod = inputs.appraisal_period?.value ?? 30;
        const discountRate = (inputs.discount_rate?.value ?? 7) / 100;
        const year1Value = yearlyData.establishment[0] || 0;
        const futureValues = yearlyData.establishment.slice(1, appraisalPeriod);
        const npv = futureValues.reduce((acc: number, value: number, index: number) => {
          return acc + (value / Math.pow(1 + discountRate, index + 1));
        }, 0);
        return year1Value + npv;
      }
    },
    "Inspections and maintenance": {
      calculate: (inputs: any, yearlyData: any) => {
        const appraisalPeriod = inputs.appraisal_period?.value ?? 30;
        const discountRate = (inputs.discount_rate?.value ?? 7) / 100;
        const year1Value = yearlyData.inspections_and_maintenance[0] || 0;
        const futureValues = yearlyData.inspections_and_maintenance.slice(1, appraisalPeriod);
        const npv = futureValues.reduce((acc: number, value: number, index: number) => {
          return acc + (value / Math.pow(1 + discountRate, index + 1));
        }, 0);
        return year1Value + npv;
      }
    },
    "Net mortality": {
      calculate: (inputs: any, yearlyData: any) => {
        const appraisalPeriod = inputs.appraisal_period?.value ?? 30;
        const discountRate = (inputs.discount_rate?.value ?? 7) / 100;
        const year1Value = yearlyData.net_mortality[0] || 0;
        const futureValues = yearlyData.net_mortality.slice(1, appraisalPeriod);
        const npv = futureValues.reduce((acc: number, value: number, index: number) => {
          return acc + (value / Math.pow(1 + discountRate, index + 1));
        }, 0);
        return year1Value + npv;
      }
    }
  }
} as const

export const input_variables = [
  {
    input: "Number of trees in the project",
    description: "This is the number of trees in the project",
    type: "number",
    category: "Broad physical parameters for project",
    param: null,
    default: 100
  },
  // ... rest of the input variables
]

export const cost_explanations = [
  {
    cost_type: "Arborist tree health inspection",
    description: "Average cost of tree health inspection report by an arborist"
  },
  // ... rest of the cost explanations
]

export const cashflow_budget_data = {
  // ... existing cashflow budget data
} 