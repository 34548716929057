<script setup lang="ts">
import { theme } from "#tailwind-config";
import { useMagicKeys, whenever } from '@vueuse/core';

/*
// ----------------------------------------------------------------------------
<template>
  <ClientOnly>
    <TabulatorTable :columns="tableColumns()" :rows="tableData" />
  </ClientOnly>
</template>
---------------------------------------------------------------------------- */

import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { TypeTabulator } from '@/types/TabulatorTableType';

// ----------------------------------------------------------------------------
// [ Props ]
type Props = {
  columns: any[];
  rows: any[];
  rowCountHeight?: number;
  option?: any;
  metadata?: any; // Added metadata prop
};
const props = withDefaults(defineProps<Props>(), {
  rowCountHeight: 0,
  option: () => TypeTabulator.Option(),
});
// [ emit ]

const emit = defineEmits<{
  'row-click': [data: any];
  'row-dbl-click': [data: any];
  'headerMouseOver': [data: any];
  'cellMouseOver': [data: any];
  'row-mouseover': [{
    event: MouseEvent,
    rowData: any,
    boundingBox: {
      x: number,
      y: number,
      width: number,
      height: number,
      top: number,
      right: number,
      bottom: number,
      left: number
    }
  }];
  init: [flag: boolean];
  tabulator: [tabulator: any];
  'table-rebuild': [func: () => void];
  'table-redraw': [func: () => void];
  'table-refresh': [func: () => void];
  'table-height': [height: number];
  'table-rect': [rect: any];
  'groupMouseMove': [data: any];
  'cellMouseLeave': [data: any];
  'cellEditing': [data: any];
  'cellEditCancelled': [data: any];
  'cellEdited': [data: any];
  'row-moved': [rowData: any];
  'column-title-changed': [{ field: string, oldTitle: string, newTitle: string }];
  'row-added': [rowData: any];
  'group-deleted': [{ groupValue: string, deletedRows: number }];
  'group-restored': [groupValue: string];
  'row-deleted': [rowData: any];
  'update:columns': [columns: any[]];
  'update:rows': [rows: any[]];
}>();


// Add this new function to emit column updates
const emitColumnUpdate = () => {
  if (tabulator.value) {
    const updatedColumns = tabulator.value.getColumns().map(col => ({
      title: col.getDefinition().title,
      field: col.getField(),
      visible: col.isVisible(),
      // Add other relevant column properties
    }));
    emit('update:columns', updatedColumns);
  }
};



// [ reactive ]
const table = ref<HTMLElement | null>(null); // reference to your table element
const tabulator = ref<any>(null); // variable to hold your table

const data = computed(() => {
  return [...props.rows];
});


const triggerDownload = (options: any) => {
  const { filename, fileType, sheetName } = options;
  if (tabulator.value) {
    let extension = fileType === "xlsx" ? "xlsx" : "csv";
    if (fileType === "xlsx" && typeof XLSX === "undefined") {
      console.error("SheetJS library is not loaded. Falling back to CSV.");
      fileType = "csv";
      extension = "csv";
    }
    tabulator.value.download(fileType, filename || `data.${extension}`);
  }
};
// Expose the method to parent components



watch(
  () => props.rows,
  () => {
    if (tabulator.value !== null) {
      tabulator.value.replaceData(data.value as any);
      hideEmptyColumns();
    }
  },
  { deep: true }
);

// watch(
//   () => storeNac.lang,
//   () => {
//     // console.log('watch  > prop.lang', tabulator.value);
//     tabulator.value.setLocale(storeNac.lang);
//     // console.log('watch  > prop.lang', tabulator.value.redraw);
//     tabulator.value.redraw(true);
//   }
// );


// Tabulator.extendModule('localize', 'langs', TypeTabulator.Option().langs);
const isInit = ref(false);

watch(isInit, (value) => {
  // console.log('table isinit', value);
  emit('init', value);
});

watch(
  () => props.columns,
  (value) => {
    tabulator.value.setColumns(props.columns);
  }
);


const deleteRow = {
  title: "",
  formatter: function (cell, formatterParams, onRendered) {
    return `<img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23888888' d='m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z'/%3E%3C/svg%3E"  class="w-4 h-4" />`
  },
  width: 40,
  maxWidth: 40,
  resizable: false,
  print: false,
  headerSort: false,
  clipboard: false,
  hozAlign: "center",
  cssClass: "delete-row-icon",
  cellClick: function (e, cell) {
    console.log('cell click', e, cell)
    const row = cell.getRow();
    const rowData = row.getData();
    row.delete();
    emit('row-deleted', rowData);
  }
}



const initTabulator = () => {
  // console.log('inittable');
  if (table.value === null) return;
  let height = 0;
  if (props.rowCountHeight !== 0) {
    height = props.rowCountHeight * 39 + 85 + 2;
  }
  const option = {
    ...props.option,
    height: height === 0 ? '' : height,
    layout: "fitColumns", // Change layout to fitColumns
    responsiveLayout: "hide", // Add this line to enable responsive layout
    columns: [
      ...props.columns.map(col => ({
        ...col,
        editableTitle: true,
        field: col.field || col.title,
        visible: col.visible !== false, // Hide column if visible is explicitly set to false
      })),
      deleteRow
    ],
    
    data: data.value,
    reactiveData: true,
    groupBy: props.option.groupBy || '_group',
    dataTree: true,
    dataTreeStartExpanded: true,
    movableRows: true, // Enable movable rows
    groupHeader: function(value, count, data, group) {
      return `<div class="group-header w-full opacity-50">
                <span>${value} <div class="inline-block text-[0.8em] inline-flex items-center justify-center aspect-square w-4 h-4 rounded-full relative -top-[1px]" style="padding: 2px; background-color: hsl(var(--muted-foreground)); color: hsl(var(--background))">${count}</div></span>
                <span class="delete-group" data-group="${value}"><img style="pointer-events: none;" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23888888' stroke-linecap='round' stroke-width='1.5' d='M9.17 4a3.001 3.001 0 0 1 5.66 0m5.67 2h-17m15.333 2.5l-.46 6.9c-.177 2.654-.265 3.981-1.13 4.79s-2.196.81-4.856.81h-.774c-2.66 0-3.991 0-4.856-.81c-.865-.809-.954-2.136-1.13-4.79l-.46-6.9M9.5 11l.5 5m4.5-5l-.5 5'/%3E%3C/svg%3E" /></span>
              </div>`;
    },
  };

  tabulator.value = new Tabulator(table.value, option);
  tabulator.value.on('rowClick', (e: any, row: any) => {
    emit('row-click', row._row.data);
    e.stopPropagation();
  });
  tabulator.value.on('rowDblClick', (e: any, row: any) => {
    emit('row-dbl-click', row._row.data);
    e.stopPropagation();
  });
  tabulator.value.on('tableBuilding', () => (isInit.value = false));
  tabulator.value.on('tableBuilt', () => {
    isInit.value = true;
    emitTableHeight(); // Emit the table height after the table is built
    emitTableRect();
    hideEmptyColumns();
  });
  tabulator.value.on('tableDestroyed', () => (isInit.value = false));

  tabulator.value.on('headerMouseOver', (e:any, column: any) => {
    emit('headerMouseOver', {e, column})
  })
  
  tabulator.value.on('cellMouseOver', function(e:any, cell: any) {
    emit('cellMouseOver', { e, cell })
    const colElement = cell._cell.column.getElement();
    const boundingBox = colElement.getBoundingClientRect();
    // console.log('cell mouse over', boundingBox)
    emit('cellMouseOver', { event: e, cell: cell, boundingBox: boundingBox })
  })

  tabulator.value.on("rowMouseOver", function(e: any, row: any) {
    const rowElement = row.getElement();
    const boundingBox = rowElement.getBoundingClientRect();

    emit('row-mouseover', { 
      event: e, 
      rowData: row._row.data,
      boundingBox: {
        x: boundingBox.x,
        y: boundingBox.y,
        width: boundingBox.width,
        height: boundingBox.height,
        top: boundingBox.top-80,
        right: boundingBox.right,
        bottom: boundingBox.bottom,
        left: boundingBox.left
      }
    });
  });
  
  tabulator.value.on("groupMouseMove", function(e: any, group: any){
    emit('groupMouseMove', { e, group })
  });

  tabulator.value.on('cellMouseLeave', function(e: any, cell: any) {
    emit('cellMouseLeave', { e, cell })
  })
  tabulator.value.on("cellEditing", function(cell: any){
    emit('cellEditing', { cell })
  });
  tabulator.value.on("cellEditCancelled", function(cell: any){
    emit('cellEditCancelled', { cell })
  });
  tabulator.value.on("cellEdited", function(cell: any){
    emit('cellEdited', { cell })
  });

  tabulator.value.on("rowMoved", function(row){
    emit('row-moved', row.getData());
  });

  // Propagate the table to the parent control
  emit('tabulator', tabulator.value);
  
  const reBuild = () => {
    if (tabulator.value === null || isInit.value === false) return;
    tabulator.value.destroy();
    tabulator.value = null;
    initTabulator();
  };

  emit('table-rebuild', reBuild);

  const redraw = () => {
    if (tabulator.value === null || isInit.value === false) return;
    tabulator.value.redraw(true);
  };
  emit('table-redraw', redraw);

  const refresh = async () => {
    if (tabulator.value === null || isInit.value === false) return;
    await tabulator.value.replaceData(data.value as any);
  };
  emit('table-refresh', refresh);

  // Add event listener for column title editing
tabulator.value.on("columnTitleChanged", function(column){
  emit('column-title-changed', { 
    field: column.getField(),
    oldTitle: column.getDefinition().title,
    newTitle: column.getDefinition().title
  });
  emitColumnUpdate();
});


  // Add a new function to emit column updates
  const emitColumnUpdate = () => {
    if (tabulator.value) {
      const updatedColumns = tabulator.value.getColumns().map(col => ({
        title: col.getDefinition().title,
        field: col.getField(),
        visible: col.isVisible(),
        // Add other relevant column properties
      }));
      emit('update:columns', updatedColumns);
    }
  };

  // Modify the existing data changed handler
  tabulator.value.on("dataChanged", function(data){
    emit('update:rows', data);
  });

  // Add event listener for row added
  tabulator.value.on("rowAdded", function(row){
    emit('row-added', row.getData());
  });

  // Set up undo functionality
  const { ctrl_z } = useMagicKeys()
  let deletedGroups: { groupValue: string, rows: any[] }[] = []

  whenever(ctrl_z, () => {
    if (deletedGroups.length > 0) {
      // Restore the last deleted group
      const lastDeletedGroup = deletedGroups.pop()!;
      if (tabulator.value && lastDeletedGroup) {
        lastDeletedGroup.rows.forEach(rowData => {
          tabulator.value.addRow(rowData);
        });

        // Optionally, emit an event to inform the parent component
        emit('group-restored', lastDeletedGroup.groupValue);
      }
    } else {
      console.log("No more groups to restore");
      // Optionally, you can emit an event or show a notification to the user
      // emit('no-more-groups-to-restore');
    }
  });

  // Add this function to clear deletedGroups when necessary
  const clearDeletedGroups = () => {
    deletedGroups = [];
  };

  // Call clearDeletedGroups when the table data is reset or new data is loaded
  watch(() => props.rows, () => {
    clearDeletedGroups();
  });

  // Modify the group deletion logic to ensure we're not adding undefined groups
  tabulator.value.on("groupClick", function(e, group) {
    if (e.target.classList.contains('delete-group')) {
      e.stopPropagation(); // Prevent group toggle
      const groupValue = e.target.getAttribute('data-group');
      
      // Get all rows in the group
      const rowsToDelete = group.getRows();
      
      if (rowsToDelete.length > 0) {
        // Store the group data before deletion
        deletedGroups.push({
          groupValue,
          rows: rowsToDelete.map(row => row.getData())
        });
        
        // Delete each row
        rowsToDelete.forEach(row => row.delete());
        
        // Optionally, emit an event to inform the parent component
        emit('group-deleted', { groupValue, deletedRows: rowsToDelete.length });

        // Hide empty columns after group deletion
        hideEmptyColumns();
      }
    }
  });

  // Add this new event listener
  tabulator.value.on("dataLoaded", hideEmptyColumns);
  tabulator.value.on("dataChanged", function (data) {
    emit('update:rows', data);
  });

  // Add these new watchers and event handlers
  watch(() => tabulator.value?.getColumns(), (newColumns) => {
    if (newColumns) {
      emit('update:columns', newColumns.map(col => ({
        title: col.getDefinition().title,
        field: col.getField(),
        visible: col.isVisible(),
        // Add other relevant column properties
      })));
    }
  }, { deep: true });

  tabulator.value.on("dataChanged", function(data){
    emit('update:rows', data);
  });

};

const triggerTabulatorFunction = (functionName: string, ...args: any[]) => {
  if (tabulator.value && typeof tabulator.value[functionName] === 'function') {
    return tabulator.value[functionName](...args);
  }
  console.warn(`Function ${functionName} not found on Tabulator instance`);
};

// Expose the method to parent components


const emitTableHeight = () => {
  if (table.value) {
    const height = table.value.offsetHeight;
    emit('table-height', height);
  }
};

const emitTableRect = () => {
  if (table.value) {
    const rect = {
      left: table.value.offsetLeft,
      right: table.value.offsetLeft + table.value.offsetWidth,
      top: table.value.offsetTop,
      bottom: table.value.offsetTop + table.value.offsetHeight
    };
    emit('table-rect', rect);
  }
};

onMounted(() => {
  initTabulator();
  emitTableHeight(); // Emit the table height on mount
});
onUnmounted(() => {
  tabulator.value.destroy();
  tabulator.value = null;
});

const addNewRow = (rowData: any = {}, position: boolean | number = false) => {
  if (tabulator.value) {
    const newRow = tabulator.value.addRow(rowData, position);
    emit('row-added', newRow.getData());
    return newRow;
  }
  console.warn("Tabulator instance not initialized");
};

const hideEmptyColumns = () => {
  if (tabulator.value) {
    const columns = tabulator.value.getColumns();
    columns.forEach(column => {
      const field = column.getField();
      // Check if field is defined before proceeding
      if (field !== undefined) {
        const isEmpty = tabulator.value.getData().every(row => {
          const value = row[field];
          return value === null || value === undefined || value === "";
        });
        if (isEmpty) {
          try {
            tabulator.value.hideColumn(field);
          } catch (error) {
            console.warn(`Failed to hide column: ${field}`, error);
          }
        } else {
          // Only show the column if it wasn't initially set to be hidden
          const columnDef = props.columns.find(col => col.field === field || col.title === field);
          if (columnDef && columnDef.visible !== false) {
            try {
              tabulator.value.showColumn(field);
            } catch (error) {
              console.warn(`Failed to show column: ${field}`, error);
            }
          }
        }
      }
    });
    // Trigger layout update after hiding/showing columns
    tabulator.value.redraw(true);
  }
};

const setTableEditing = (enabled: boolean) => {
  if (tabulator.value?.table) {
    tabulator.value.table.getColumns().forEach(column => {
      column.updateDefinition({ editable: enabled })
    })
  } 
}

// Watch for changes in the rows data
watch(() => props.rows, () => {
  if (tabulator.value !== null) {
    tabulator.value.replaceData(data.value as any);
    hideEmptyColumns();
  }
}, { deep: true });

defineExpose({
  emitColumnUpdate,
  triggerTabulatorFunction,
  addNewRow,
  triggerDownload,
  hideEmptyColumns,
  setTableEditing
});

</script>
<template>
  <div ref="table" class="w-full h-full my-0 py-0"></div>
</template>

<style lang="scss">



.tabulator-cell {
    font-size: 11px;
    color: hsl(var(--primary));
}

// .tabulator * {
//     &::-webkit-scrollbar-track {
//         border-radius: 4px;
//         background: #fff;
//     }

//     &::-webkit-scrollbar {
//         width: 14px;
//         background: transparent;
//     }

//     &::-webkit-scrollbar-thumb {
//         border-radius: 4px;
//         // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//         // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//         background: #212121; 
//     }

//     .tabulator-table {
//         background: transparent;
//         color: #fff;
//     }
// }

.group-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.delete-group {
  cursor: pointer;
  padding: 0 5px;
}


div[role="rowgroup"] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.delete-row-icon {
  border: none !important;
  opacity: 0;
}

.tabulator-row.tabulator-selected {
  background-color: transparent !important;
}

</style>