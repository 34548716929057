<script setup lang="ts">
import { Line } from 'vue-chartjs'
import type { MaintenancePeriod } from '~/types/maintenance'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import DragDataPlugin from 'chartjs-plugin-dragdata'

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  DragDataPlugin
)

const props = defineProps<{
  modelValue: MaintenancePeriod[]
  maxYears?: number
  postEstablishmentYears?: number
}>()

const emit = defineEmits<{
  'update:modelValue': [value: MaintenancePeriod[]]
  'update:postEstablishmentYears': [value: number]
}>()

const postEstablishmentYears = ref(props.postEstablishmentYears ?? 6)

// Update the adjustPostEstablishmentPeriod function
const adjustPostEstablishmentPeriod = () => {
  // Cycle through options: 2 to 8 years
  const options = [2, 3, 4, 5, 6, 7, 8]
  const currentIndex = options.indexOf(postEstablishmentYears.value)
  // If current value isn't found or is last, start from beginning
  const nextIndex = currentIndex === -1 || currentIndex === options.length - 1 ? 0 : currentIndex + 1
  const newValue = options[nextIndex]
  
  // Update local ref and emit new value
  postEstablishmentYears.value = newValue
  emit('update:postEstablishmentYears', newValue)
}

// Also update the postEstablishmentYears ref to watch for prop changes
watch(() => props.postEstablishmentYears, (newValue) => {
  if (newValue !== undefined) {
    // Constrain value between 2 and 8
    const constrainedValue = Math.max(2, Math.min(8, newValue))
    postEstablishmentYears.value = constrainedValue

    // Adjust points based on range
    let newData = [...props.modelValue]
    
    if (constrainedValue === 2) {
      // For 2 years, keep only first and last points
      newData = [
        { year: 1, intensity: newData[0].intensity },
        { year: 2, intensity: newData[newData.length - 1].intensity }
      ]
    } else {
      // For other ranges, ensure we have all three points
      if (newData.length === 2) {
        // If we're coming from 2 years, add middle point back
        const midYear = Math.round((constrainedValue - 1) / 2) + 1
        const midIntensity = Math.round((newData[0].intensity + newData[1].intensity) / 2)
        newData = [
          { year: 1, intensity: newData[0].intensity },
          { year: midYear, intensity: midIntensity },
          { year: constrainedValue, intensity: newData[1].intensity }
        ]
      } else {
        // Adjust existing points to stay within range
        newData = newData.map((point, index) => {
          if (index === 0) {
            return { year: 1, intensity: point.intensity }
          } else if (index === newData.length - 1) {
            return { year: constrainedValue, intensity: point.intensity }
          } else {
            // Middle point should be proportionally placed
            const midYear = Math.round((constrainedValue - 1) / 2) + 1
            return { year: midYear, intensity: point.intensity }
          }
        })
      }
    }
    
    emit('update:modelValue', newData)
  }
}, { immediate: true })

// Chart data
const chartData = computed(() => ({
  datasets: [{
    label: 'Maintenance Intensity',
    // Filter out middle point if postEstablishmentYears is 2
    data: props.modelValue
      .filter((p, index) => {
        if (postEstablishmentYears.value === 2) {
          // Keep only first and last points
          return index === 0 || index === props.modelValue.length - 1;
        }
        // Keep all points for other ranges
        return true;
      })
      .map(p => ({
        x: p.year,
        y: p.intensity
      })),
    borderColor: '#8B5CF6',
    backgroundColor: 'rgba(139, 92, 246, 0.1)',
    tension: 0.4,
    fill: true,
    pointRadius: 8,
    pointHoverRadius: 10,
    pointBackgroundColor: '#8B5CF6',
    pointBorderColor: '#fff',
    pointBorderWidth: 2,
  }]
}))

// Chart options
const chartOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index'
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      titleColor: '#334155',
      bodyColor: '#334155',
      borderColor: '#E2E8F0',
      borderWidth: 1,
      padding: 10,
      displayColors: false,
      callbacks: {
        title: (context: any[]) => {
          return `Year ${Math.round(context[0].parsed.x)}`
        },
        label: (context: any) => {
          return `Intensity: ${context.parsed.y.toFixed(0)}%`
        }
      }
    },
    dragData: {
      round: 1,
      dragX: true,
      dragY: true,
      showTooltip: true,
      onDragStart: function(e: any, element: any) {
        e.target.style.cursor = 'grabbing'
      },
      onDrag: function(e: any, datasetIndex: number, index: number, value: { x: number; y: number }) {
        // Constrain X (year) movement
        const newData = [...props.modelValue]
        const minYear = index === 0 ? 1 : newData[index - 1].year + 1
        const maxYear = index === newData.length - 1 ? 
          postEstablishmentYears.value : // Remove Math.max here
          newData[index + 1].year - 1
        
        // Round and constrain values
        const year = Math.max(minYear, Math.min(maxYear, Math.round(value.x)))
        const intensity = Math.max(0, Math.min(100, Math.round(value.y)))
        
        // Update the point
        newData[index] = { year, intensity }
        emit('update:modelValue', newData)
      },
      onDragEnd: function(e: any, element: any) {
        e.target.style.cursor = 'default'
      }
    }
  },
  scales: {
    x: {
      type: 'linear',
      min: 1,
      max: postEstablishmentYears.value,  // Remove Math.max here
      grid: {
        color: '#E2E8F0',
        drawBorder: false
      },
      ticks: {
        stepSize: 1,
        color: '#64748B',
        font: {
          size: 11
        },
        callback: (value: number) => `${value}`
      },
      title: {
        display: true,
        text: 'Year',
        color: '#64748B',
        font: {
          size: 12,
          weight: '500'
        }
      }
    },
    y: {
      min: 0,
      max: 100,
      grid: {
        color: '#E2E8F0',
        drawBorder: false
      },
      ticks: {
        stepSize: 20,
        color: '#64748B',
        font: {
          size: 11
        },
        callback: (value: number) => `${value}%`
      },
      title: {
        display: true,
        text: 'Maintenance Intensity',
        color: '#64748B',
        font: {
          size: 12,
          weight: '500'
        }
      }
    }
  }
}))
</script>

<template>
  <div class="relative">
    <div class="absolute inset-0 bg-gradient-to-b from-transparent to-background/5 pointer-events-none" />
    
    <!-- Add button for adjusting post-establishment period -->
    <div class="absolute top-0 right-0 z-10">
      <Button
        variant="ghost"
        size="sm"
        class="h-8 px-2 text-xs flex items-center gap-1 text-violet-500 hover:text-violet-600 hover:bg-violet-100/50"
        @click="adjustPostEstablishmentPeriod"
      >
        <span class="text-violet-500">{{ postEstablishmentYears }} years</span>
        <Icon name="lucide:chevron-right" class="h-4 w-4 text-violet-500" />
      </Button>
    </div>
    
    <div class="h-[200px]">
      <Line
        :data="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}

:deep(canvas) {
  cursor: pointer;
}

:deep(canvas:active) {
  cursor: grabbing;
}
</style> 